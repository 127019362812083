import { Injectable } from '@angular/core';

import { LOCAL_STORAGE_KEYS } from '@ib-enums/local-storage-keys.enum';
import { IB_ROUTES } from '@ib-enums/routes.enum';
import { Router } from '@angular/router'
import { environment } from '@ib-env/environment';
import {
  IAuthUser,
  IAuthUserCreate,
  IAuthUserWithJwt,
} from '@ib-shared/interfaces/auth-user.intf';
import * as Sentry from '@sentry/angular-ivy';
import { IInvestorWithTokens, IJwtTokens } from 'credebt-shared';
import { delay, map, Observable, tap } from 'rxjs';

import { AuthHttpService } from './auth-http.service';
import { AuthStateService } from './auth-state.service';
import { LocalStorageService } from './local-storage.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthFacadeService {
  constructor(
    private authStateS: AuthStateService,
    private localStorageS: LocalStorageService,
    private navS: NavigationService,
    private authHttpS: AuthHttpService,
    private router: Router,
  ) {}

  selectAuthUser(): Observable<IAuthUser | null> {
    return this.authStateS.user$.asObservable();
  }

  getMe(): Observable<IAuthUser> {
    return this.authHttpS.getMe().pipe(tap(user => this.setAuthUser(user)));
  }

  selectIsAuthenticated(): Observable<{ value: boolean }> {
    return this.authStateS.user$
      .asObservable()
      .pipe(map(r => ({ value: Boolean(r) })));
  }

  get authUser(): IAuthUser | null {
    return this.authStateS.user$.value;
  }

  get isRegistrAdditDocModalWasInited(): boolean {
    return this.authStateS.registrationDocsModalWasInited;
  }

  set registrAdditDocModalWasInited(v: boolean) {
    this.authStateS.registrationDocsModalWasInited = v;
  }

  setAuthUser(v: IAuthUser | null): void {
    if (v) {
      this.localStorageS.set(LOCAL_STORAGE_KEYS.AUTH_USER, v);
    } else {
      this.localStorageS.remove(LOCAL_STORAGE_KEYS.AUTH_USER);
    }
    Sentry.setUser({
      email: v?.email || 'not-authenticated-user',
      username: v?.name || 'not-authenticated-user',
    });
    this.authStateS.user$.next(v);
  }

  login(p: { email: string; password: string }): Observable<IAuthUserWithJwt> {
    return this.authHttpS.login(p).pipe(
      tap(data => {
        this.setAuthUser(data.user);
        if (data.tokens) {
          this.setJwtTokens(data.tokens);
        }
        this.navS.goToRoute(IB_ROUTES.DASHBOARD);
      }),
    );
  }

  forgotPassword(p: { email }): Observable<boolean> {
    return this.authHttpS.forgotPassword(p);
  }

  resetPassword(p: { code: string; newPassword: string }): Observable<boolean> {
    return this.authHttpS.resetPassword(p);
  }

  setJwtTokens(v: IJwtTokens | null): void {
    this.setAccessToken(v?.access);
    this.setRefreshToken(v?.refresh);
  }

  setAccessToken(v: string | undefined): void {
    this.authStateS.accessToken = v;
    if (v) {
      this.localStorageS.set(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, v);
    } else {
      this.localStorageS.remove(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    }
  }

  setRefreshToken(v: string | undefined): void {
    this.authStateS.refreshToken = v;
    if (v) {
      this.localStorageS.set(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, v);
    } else {
      this.localStorageS.remove(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    }
  }

  get accessToken(): string | undefined {
    return this.authStateS.accessToken;
  }
  get refreshToken(): string | undefined {
    return this.authStateS.refreshToken;
  }

  selectIsLogout(): Observable<boolean> {
    return this.authStateS.logout$.asObservable();
  }

  register(
    payload: IAuthUserCreate,
    file: File,
  ): Observable<IInvestorWithTokens> {
    return this.authHttpS.signUp(payload, file).pipe(
      delay(environment.production ? 0 : 2000),
      tap(data => {
        this.setAuthUser(data.user);
        this.setJwtTokens(data.tokens);
        this.navS.goToRoute(IB_ROUTES.EMAIL_CONFIRMATION);
      }),
    );
  }

  logout(): void {
    const refreshToken = this.localStorageS.get(
      LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
    ) as string;
    const logout = (): void => {
      this.setAuthUser(null);
      this.setJwtTokens(null);
      setTimeout(() => {
        this.navS.goToRoute(IB_ROUTES.LOGIN);
      }, 10);
    };
    if (refreshToken) {
      this.authStateS.logout$.next(true);
      this.authHttpS.logout(refreshToken).subscribe({
        next: () => {
          this.authStateS.logout$.next(false);
          logout();
        },
        error: () => {
          this.authStateS.logout$.next(false);
          logout();
        },
      });
    } else {
      logout();
    }
  }
  loginRedirect(): void {
    console.log(`Navigating to: /${IB_ROUTES.LOGIN}`);
    this.router.navigate([`/${IB_ROUTES.LOGIN}`]);
  }
  
}
